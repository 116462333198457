.btn {
  display: flex;
  width: fit-content;
}

.btn-style-1 {
  background-color: #fe7c66;
  color: white;
  padding: 10px 20px;
  border: none;
  text-transform: uppercase;
  transition: background-color 0.3s ease;
}

.btn-style-1:hover {
  background-color: #0a303a;
  color: white;
}

.btn-style-2 {
  background-color: #0a303a;
  color: white;
  padding: 10px 20px;
  border: none;
  text-transform: uppercase;
  transition: background-color 0.3s ease;
}

.btn-style-2:hover {
  background-color: #fe7c66;
  color: white;
}

.btn-icon {
  margin-left: 10px;
  vertical-align: middle;
  width: 24px;
  height: auto;
}
